import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const History = () => (
  <Layout>
    <div className="terms-use legal">
    <h1>Terms of Use</h1>
       <p>
         Thank you for considering BML Ventures for your mining needs. Below is our Terms of Use policy:
       </p>
       <h2>1. Introduction</h2>
       <p>
         By using BML Ventures website, you agree to the terms and conditions set out below. If you do not agree with these terms and conditions, please do not use this website.
       </p>
       <h2>2. Intellectual Property Rights</h2>
       <p>
         All content on this website, including but not limited to text, images, graphics, logos, trademarks, and other intellectual property rights are the property of BML Ventures or its affiliates. You may not copy, reproduce, republish, upload, post, transmit, or distribute any material on this website without our prior written consent.
       </p>
       <h2>3. Use of Website</h2>
       <p>
         You may use this website for lawful purposes only. You may not use this website in any way that is harmful or inappropriate, including but not limited to:
       </p>
       <ul>
         <li>Using this website to transmit any unsolicited or unauthorized advertising or promotional material.</li>
         <li>Interfering with the operation of this website or disrupting this website or any networks connected to this website.</li>
         <li>Hacking or attempting to hack any aspect of this website or any networks connected to this website.</li>
         <li>Impersonating any person or entity or misrepresenting your affiliation with any person or entity.</li>
       </ul>
       <h2>4. Disclaimer of Liability</h2>
       <p>
         The information provided on this website is for general information purposes only. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. BML Ventures will not be liable for any loss or damage arising from your use of this website.
       </p>
       <h2>5. Changes to Terms of Use</h2>
       <p>
         We reserve the right to update or modify these Terms of Use at any time without prior notice. Your continued use of this website after any changes to these Terms of Use constitutes your acceptance of such changes.
       </p>
       <p>If you have any questions or concerns about our Terms of Use policy, please contact us at info@bmlventures.com.</p>
       </div>
  </Layout>
)

export const Head = () => <Seo title="Terms of Use"/>

export default History
